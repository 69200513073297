import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import 'core-js/es'
import 'es6-promise/auto'

import { theme } from 'get-theme'
import ErrorCatcher from 'error-catcher'
import LoginRedirector from 'login-redirector'
import ConsoleApp from 'console'
// import { initFirebaseRemoteConfig } from 'firebase-utils'
// import { getPhoneCom } from 'phonecom'

// import Spinner from 'spinner'
import { ThemeProvider } from 'theme-provider'
import { PhoenixSessionProvider } from 'providers'

// ReactDOM.render(
//     <ErrorCatcher theme={theme}>
//         <div style={{
//             marginLeft: '50%',
//             top: '50%',
//             position: 'absolute'
//         }}>
//             <Spinner />
//         </div>
//     </ErrorCatcher>,
//     document.getElementById('root'))

ReactDOM.render(
    <ErrorCatcher theme={theme}>
        <ThemeProvider theme={theme}>
            <PhoenixSessionProvider>
                <LoginRedirector>
                    <App />
                </LoginRedirector>
            </PhoenixSessionProvider>
        </ThemeProvider>
    </ErrorCatcher>,
    document.getElementById('root'))

function App (pros) {
    // const [phoneCom, setPhoneCom] = useState({})

    useEffect(() => {
        // initFirebaseRemoteConfig()
        // getPhoneCom().then((phoneComObject) => {
        //     setPhoneCom(phoneComObject)
        // }
        // )
    }, [])

    return <ConsoleApp />
    //         : <div style={{
    //             marginLeft: '50%',
    //             top: '50%',
    //             position: 'absolute'
    //         }}>
    //             <Spinner />
    //             <div style={{ paddingTop: 10, marginLeft: -15 }}>Loading ...</div>
    //         </div>
}
