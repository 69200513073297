import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7163
 *
 * @param {SvgIconProps} props - svg props
 */
export const AddButtonIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M11.3334 6.66675C11.1493 6.66675 11.0001 6.81599 11.0001 7.00008V11.0001H7.00008C6.81599 11.0001 6.66675 11.1493 6.66675 11.3334V12.6667C6.66675 12.8508 6.81599 13.0001 7.00008 13.0001H11.0001V17.0001C11.0001 17.1842 11.1493 17.3334 11.3334 17.3334H12.6667C12.8508 17.3334 13.0001 17.1842 13.0001 17.0001V13.0001H17.0001C17.1842 13.0001 17.3334 12.8508 17.3334 12.6667V11.3334C17.3334 11.1493 17.1842 11.0001 17.0001 11.0001H13.0001V7.00008C13.0001 6.81599 12.8508 6.66675 12.6667 6.66675H11.3334Z' fill='white'/>
        </SvgIcon>
    )
}

export default AddButtonIcon
