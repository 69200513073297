/* eslint class-methods-use-this : "off" */
import l from '../lang'
import csv_helpers from './csv-helpers'
import { ControlledError } from 'errors'

/**
 *
 */
export default class CSVTransformer {
    /**
     *  Transforms Contact object to csv format.
     *
     * @param {object} session PhoeniApiJsClient object
     * @param {file} file file
     * @param {object} headers extension_id
     */
    constructor (session, file, headers) {
        this.session = session
        this.file = file
        this.headers = headers
    }

    /**
     *
     * @param {file} f
     * @returns
     */
    csvToJson (f) {
        this.csv_incorrect_lines = []
        const file = f || this.file
        const lines = file.split('\n')
        if (lines[lines.length - 1] === '') lines.pop()
        let items = []
        const headers = lines[0].split(',').map((x) => csv_helpers.csv_object_value(x))
        this.compare_headers(headers)
        const headers_keys = this.headers ? Object.keys(this.headers) : []
        if (lines.length === 2 && lines[1] === '') {
            throw new ControlledError(l.t('csv.empty-file', 'Your csv file is empty.'))
        }
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i]
            const obj = {}
            const current_line_array = line.split(',')
            const validate_line = csv_helpers.validate_line_length(current_line_array, headers, i)
            if (validate_line !== 'OK') {
                this.csv_incorrect_lines.push(validate_line)
                // eslint-disable-next-line no-continue
                continue
            }
            for (let j = 0; j < headers.length; j++) {
                if (this.headers[headers_keys[j]]) {
                    const value = csv_helpers.csv_object_value(current_line_array[j])
                    obj[this.headers[headers_keys[j]]] = value === '' ? null : value
                }
            }
            items.push(obj)
        }
        items.shift()
        if (this.setup && this.setup.jsonCallback) {
            items = this.setup.jsonCallback(items)
        }
        return items
    }

    /**
     *
     * @param {object} headers
     * @returns
     */
    compare_headers (headers) {
        if (this.headers) {
            const allowed_values = Object.keys(this.headers)
            const different_fields = csv_helpers.clean_rs(headers).filter((x) => !allowed_values.includes(x))
            if (different_fields.length) {
                throw new ControlledError(
                    l.t(
                        'csv.incorrect-headers',
                        'Your csv file has incorrect format. It contains unsupported fields: {}.',
                        [different_fields.join(', ')]
                    )
                )
            }
        }

        return true
    }
}
