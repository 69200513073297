<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-form @submit="update" @changed="may_submit = true" data-discard="true" data-test-id="device-form">
          <FormInput
            :label="l.t('app.device-name', 'Device name')"
          >
            <w-text-field
              v-model="device.name"
              hide-details='auto'
              data-test-id="device-name-input"
            />
          </FormInput>
          <SectionTitle data-test-id="device-lines-title">
              {{ l.t("devices.lines", "Lines") }}
          </SectionTitle>
          <FormInput
            v-for="(line, i) in device['lines']"
            :label="`${l.t('app.line', 'Line')} ${device['lines'][i]['line']}`"
            :key="line.line"
          >
              <ExtensionSelector
                v-model="line.extension"
                :options_list="extension_selector_options"
                :placeholder="l.t('app.select-an-extension', 'Select an extension')"
                :filtered_values="filtered_extensions"
                :rules="[$validation.required()]"
                @click:remove="$delete(device['lines'], i)"
                :return_object="true"
                :data-test-id="`device-line-${i}-extensions-selector`"
              />
          </FormInput>
          <FormInput>
            <AddToListButton @click="addNewLine" :disabled="maxNumberOfLinesExceeded()" :title="device.id && maxNumberOfLinesExceeded() ? l.t('devices, max-num-of-lines', 'Maximum number of lines reached: {}', [device.lines.length]) : null" data-test-id="device-add-new-line-btn">
              {{ l.t("devices.add-line", "Add line") }}
            </AddToListButton>
          </FormInput>
          <FormInput class="mt-3">
            <w-btn type="submit" :disabled="!may_submit" class="primary" data-test-id="device-submit-btn">
              {{ l.t("app.save", "Save") }}
            </w-btn>
          </FormInput>
        </w-form>
    </div>
</template>

<script>
  import {nxt_company_inbox_extension, is_csr} from 'phoenix-session-helpers';
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import ExtensionSelector from './ExtensionSelector.vue';
  import AddToListButton from '../elements/AddToListButton.vue';

  export default {
    props: ['_device'],
    components: {
      FormInput,
      SectionTitle,
      AddToListButton,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        loading: false,
        device: null,
        may_submit: false,
        company_inbox_extension: null,
        filtered_extensions: [],
        extension_selector_options: ['call-flow-link'],
      };
    },
    async created() {
      this.$data.device = this.$props._device;
      this.$data.device.loading = false;
      this.$data.company_inbox_extension = await nxt_company_inbox_extension(this.$session);
      if (this.$data.company_inbox_extension) {
        this.$data.filtered_extensions = [this.$data.company_inbox_extension];
      }
      const is_user_csr = await is_csr(this.$session);
      if (is_user_csr) {
        this.$data.extension_selector_options.push('remove')
      }
    },
    methods: {
      maxNumberOfLinesExceeded() {
        if (
          this.$data.device.model
          && this.$data.device.model.lines
          && this.$data.device.lines.length >= this.$data.device.model.lines
        ) {
          return true;
        }

        return false;
      },
      extensionChanged(i, event) {
        this.$data.device['lines'][i]['extension'] = { id: event.id };
      },
      async update(e) {
        if (e) e.preventDefault();
        this.prepareDeviceForUpdate();
        this.$emit('updateDevice', this.$data.device);
      },
      prepareDeviceForUpdate() {
        for (const line of this.$data.device.lines) {
          line.line = parseInt(line.line);
        }
      },
      generateNewLine() {
        const lineNumber = this.generateLineNumber();
        return {
          line: lineNumber,
          extension: null,
        };
      },
      generateLineNumber(number = 1) {
        if (this.$data.device.lines.find((x) => x.line === number)) {
          return this.generateLineNumber(number + 1);
        }
        return number;
      },
      addNewLine() {
        this.$data.device['lines'].push(this.generateNewLine());
      },
    },
  };
</script>
