<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert v-if="alert" :level="alert.level" :message="alert.message" class="mb-6"/>
        <w-form
            @submit="update"
            @changed="may_submit = true"
            :readonly="readonly"
            data-discard="true"
        >
            <SectionTitle data-test-id="extension-general-title">
                {{ l.t('extensions.general-settings', "General settings") }}
            </SectionTitle>
            <FormInput
                :label="l.t('app.nickname', 'Nickname')"
            >
                <w-text-field
                    v-model="extension.name"
                    hide-details='auto'
                    :not-clearable="readonly"
                    :rules="[$validation.required(), $validation.maxlength(30)]"
                    data-test-id="extension-nickname-input"
                />
            </FormInput>
            <FormInput
                :label="l.t('app.extension', 'Extension')"
            >
                <w-text-field
                    type="number"
                    :not-clearable="readonly"
                    @wheel="$event.currentTarget.blur()"
                    @keydown="($event.key === 'ArrowUp' || $event.key === 'ArrowDown') && $event.preventDefault()"
                    :rules="[$validation.min(10)]"
                    v-model="extension.extension"
                    hide-details='auto'
                    data-test-id="extension-num-input"
                />
            </FormInput>
            <FormInput
                :label="l.t('app.name', 'Name')"
            >
                <w-text-field
                    v-model="extension.full_name"
                    hide-details='auto'
                    :not-clearable="readonly"
                    :rules="[$validation.required(), $validation.maxlength(70)]"
                    data-test-id="extension-full-name-input"
                />
            </FormInput>
            <FormInput
                :label="l.t('extensions.recorded-name', 'Recorded name')"
            >
                <w-switch
                    :label="l.t('app.enable', 'Enable')"
                    v-model="greetings_enabled.name"
                    hide-details='auto'
                    data-test-id="extension-recorded-name-switch"
                />
                <SelectorWithCreate
                    v-if="greetings_enabled.name"
                    v-model="extension.name_greeting"
                    :return_object="true"
                    :filtered_values="excluded_extensions_ids"
                    mode="greetings"
                    data-test-id="extension-recorded-greeting-selector"
                />
            </FormInput>
            <FormInput
                :label="l.t('app.timezone', 'Timezone')"
            >
                <TimezoneSelector
                    v-model="extension.timezone"
                    data-test-id="extension-timezone-selector"
                />
            </FormInput>
            <FormInput
                :label="l.t('extensions.include-in-dial-by-name-directory', 'Include in dial-by-name directory')"
            >
                <w-switch
                    v-model="extension.include_in_directory"
                    :label="l.t('app.include', 'Include')"
                    hide-details='auto'
                    data-test-id="extension-include-in-dir-switch"
                />
            </FormInput>
            <SectionTitle data-test-id="extension-outbound-title">{{ l.t('extensions.outbound-calls', "Outbound calls") }}</SectionTitle>
            <FormInput
                :label="l.t('extensions.caller-id-to-use', 'Caller ID to use')"
            >
                <APIAutocomplete
                    mode="phone_numbers"
                    :return_object="true"
                    :before_list="[{ value: null, text: l.t('extensions.private', 'private') }]"
                    :value="!extension.caller_id || extension.caller_id === 'private' ? null : extension.caller_id"
                    @input="(v) => extension.caller_id = v ? v : 'private'"
                    data-test-id="extension-caller-id-selector"
                />
                <p
                    v-if="
                        extension.caller_id && extension.caller_id !== 'private' &&
                        (extension.caller_id.phone_number.startsWith('+1800') ||
                        extension.caller_id.phone_number.startsWith('+1833') ||
                        extension.caller_id.phone_number.startsWith('+1844') ||
                        extension.caller_id.phone_number.startsWith('+1855') ||
                        extension.caller_id.phone_number.startsWith('+1866') ||
                        extension.caller_id.phone_number.startsWith('+1877') ||
                        extension.caller_id.phone_number.startsWith('+1888'))
                    "
                    class="mt-2"
                    data-test-id="extension-+18-help-text"
                >
                    {{ l.t('extensions.toll-free-numbers-small-text', 'Toll-free numbers cannot be used as caller ID when making outbound calls to other toll-free numbers. In this case, your caller ID will be replaced.') }}
                </p>
            </FormInput>
            <FormInput
                :label="l.t('extensions.local-area-code', 'Local area code')"
            >
                <w-text-field
                    v-model="extension.local_area_code"
                    @wheel="$event.currentTarget.blur()"
                    @keydown="($event.key === 'ArrowUp' || $event.key === 'ArrowDown') && $event.preventDefault()"
                    type="number"
                    :rules="[$validation.area_code()]"
                    hide-details='auto'
                    :not-clearable="readonly"
                    data-test-id="extension-local-code-input"
                />
            </FormInput>
            <SectionTitle data-test-id="extension-voicemail-title">{{ l.t('app.voicemail', "Voicemail") }}</SectionTitle>
            <FormInput
                :label="l.t('app.enabled', 'Enabled')"
            >
                <w-switch
                    v-model="extension.voicemail.enabled"
                    :label="l.t('app.enable', 'Enable')"
                    hide-details='auto'
                    data-test-id="extension-voicemail-enable-switch"
                />
            </FormInput>
            <template v-if="extension.voicemail.enabled">
                <template
                    v-if="(extension.voicemail.greeting.standard) || (extension.voicemail.greeting.alternate) || (extension.name_greeting)">
                    <FormInput
                        :label="l.t('extensions.voicemail-greeting', 'Voicemail greeting')"
                    >
                        <w-select
                             v-model="extension.voicemail.greeting.type" :disabled="!extension.voicemail.enabled"
                            :items="voicemail_greeting_types()"
                            hide-details="auto"
                            data-test-id="extension-voicemail-greeting-select"
                        >
                        </w-select>
                    </FormInput>
                </template>
                <FormInput
                    :label="l.t('extensions.standard-greeting', 'Standard greeting')"
                >
                    <w-switch
                        v-model="greetings_enabled.standard"
                        :label="l.t('app.enable', 'Enable')"
                        hide-details='auto'
                        data-test-id="extension-standard-greeting-enable"
                    />
                    <SelectorWithCreate
                        v-if="greetings_enabled.standard"
                        v-model="extension.voicemail.greeting.standard"
                        :return_object="true"
                        :filtered_values="excluded_extensions_ids"
                        mode="greetings"
                        data-test-id="extension-standard-geret-selector"
                    />
                </FormInput>
                <FormInput
                    :label="l.t('extensions.alternate-greeting', 'Alternate greeting')"
                >
                    <w-switch
                        v-model="greetings_enabled.alternate"
                        :label="l.t('app.enable', 'Enable')"
                        hide-details='auto'
                        data-test-id="extension-alternate-greet-switch"
                    />
                    <SelectorWithCreate
                        v-if="greetings_enabled.alternate"
                        v-model="extension.voicemail.greeting.alternate"
                        :return_object="true"
                        :filtered_values="excluded_extensions_ids"
                        mode="greetings"
                        data-test-id="extension-alternate-greet-selector"
                    />
                </FormInput>
                <FormInput
                    :label="l.t('extensions.leave-a-message-prompt', '\'Leave a message\' prompt')"
                >
                    <w-switch
                        v-model="extension.voicemail.greeting.enable_leave_message_prompt"
                        :label="l.t('extensions.play-this-message', 'Play this message after my greeting: \'Please leave your message after the tone. When finished, hang up or press the pound key.\'')"
                        hide-details='auto'
                        data-test-id="extension-leave-mess-prompt-switch"
                    />
                </FormInput>
                <FormInput
                    :label="l.t('app.password', 'Password')"
                >
                    <div class="d-flex">
                        <w-text-field
                            type="number"
                            :rules="[$validation.minlength(4), $validation.maxlength(25)]"
                            :not-clearable="readonly"
                            @wheel="$event.currentTarget.blur()"
                            @keydown="($event.key === 'ArrowUp' || $event.key === 'ArrowDown') && $event.preventDefault()"
                            v-model="extension.voicemail.password"
                            :disabled="!extension.voicemail.enabled"
                            hide-details='auto'
                            class="hide-arrows fs-exclude"
                            data-test-id="extension-password-input"
                        />
                        <w-tooltip bottom no-carret>
                            <template v-slot:activator>
                                <w-btn
                                    v-if="!readonly"
                                    type="button"
                                    @click="resetPassword"
                                    :disabled="!extension.voicemail.enabled"
                                    fab
                                    x-small
		                                changes-form
                                    class="primary primary--text lighten-5 ml-3 mt-2"
                                    data-test-id="extension-reset-pass-btn"
                                >
                                  <v-icon>$vuetify.icons.reload</v-icon>
                                </w-btn>
                            </template>
                            <span>{{ l.t('app.reset-password', 'Reset password') }}</span>
                        </w-tooltip>
                    </div>
                </FormInput>
            <ClassicOrNxt user="classic" tag="div">
              <FormInput
                  :label="l.t('extensions.voicemail-transcription', 'Voicemail transcription')"
              >
                  <w-switch
                      :value="!!extension.voicemail.transcription"
                      @change="(event) => extension.voicemail.transcription = event ? 'automated' : null"
                      :label="l.t('extensions.transcribe-voicemail-messages-to-text', 'Transcribe voicemail messages to text')"
                      hide-details='auto'
                      data-test-id="extension-transcription-options-switch"
                  />
                  <div v-if="extension.voicemail.transcription === 'automated'" class="mb-3">
                    {{l.t('extensions.automated-transcriptions', 'Automated Transcriptions - Fees: $1.50 per month. Unlimited transcriptions.')}}
                  </div>
                  <a href="https://www.phone.com/phone-features/#voicemail-transcription" target="_blank" data-test-id="extension-learn-more">{{ l.t('app.learn-more', 'Learn more') }}</a>
                  <a href="https://www.phone.com/general-terms/" target="_blank" class="ml-3" data-test-id="extension-terms">{{ l.t('app.terms-of-services', 'Terms of services') }}</a>
              </FormInput>
            </ClassicOrNxt>
            </template>
            <SectionTitle data-test-id="extension-notifications-title">{{ l.t('app.notifications', "Notifications") }}</SectionTitle>
            <FormInput
                v-if="extension.voicemail.enabled"
                :label="l.t('extensions.messages-notifications', 'SMS, fax and voicemail notifications')"
            >
                <SmsEmailNotifications
                :label="l.t('app.incoming-call-notifications-text', 'Enable notifications')"
                v-model="extension.voicemail.notifications"
                />
            </FormInput>
            <FormInput
                :label="l.t('app.incoming-call-notifications', 'Incoming call notifications')"
            >
                <SmsEmailNotifications
                  v-model="extension.call_notifications"
                  :label="l.t('app.incoming-call-notifications-text', 'Enable notifications')"
                />
            </FormInput>
            <FormInput v-if="generic_device_option" :label="l.t('extensions.add-generic-device-on-ext', 'Add generic device to extension')">
                <w-checkbox
                    v-model="extension.add_generic_device"
                    class="mt-1"
                    hide-details="auto"
                    data-test-id="add-generic-device-checkbox"
                />
            </FormInput>
            <template v-if="extension.device_membership">
                <SectionTitle data-test-id="extension-device-membership-title">
                    {{ l.t('extensions.presence-settings', "Presence settings") }}
                </SectionTitle>
                <template class="voicemail-settings" v-if="extension.voicemail.enabled">
                    <FormInput
                        :label="l.t('extensions.share-this-phones-status:', 'Share this phone’s status')"
                    >
                        <w-switch
                            v-model="extension.presence_publish"
                            :label="l.t('app.enable', 'Enable')"
                            hide-details='auto'
                            data-test-id="extension-presence-publish-switch"
                        />
                    </FormInput>
                    <FormInput
                        :label="l.t('extensions.monitor-status-of-other-phones', 'Monitor status of other phones')"
                    >
                        <w-switch
                            v-model="extension.presence_subscribe"
                            :disabled="extension.presence_subscribe_support === false"
                            :label="l.t('app.enable', 'Enable')"
                            hide-details='auto'
                            data-test-id="extension-presence-subscribe-support-switch"
                        />
                    </FormInput>
                </template>
            </template>
            <FormInput>
                <w-btn type="submit" :disabled="readonly || !may_submit" class="primary" data-test-id="extension-submit-btn">
                    {{ l.t('app.save', "Save") }}
                </w-btn>
            </FormInput>
        </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';
  import SectionTitle from '../elements/SectionTitle.vue';
  import ClassicOrNxt from '../elements/ClassicOrNxt.vue';
  import TimezoneSelector from '../elements/TimezoneSelector.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
  import SelectorWithCreate from '../elements/SelectorWithCreate.vue';
  import SmsEmailNotifications from '../elements/SmsEmailNotifications.vue';

  export default {
    props: ['_extension', 'id', 'generic_device_option', 'readonly'],
    components: {
    APIAutocomplete,
    FormInput,
    SectionTitle,
    ClassicOrNxt,
    TimezoneSelector,
    SelectorWithCreate,
    SmsEmailNotifications,
},
    data() {
      return {
        l,
        loading: true,
        alert: null,
        extension: null,
        may_submit: false,
        greetings_enabled: {
          name: false,
          standard: false,
          alternate: false,
        },
      };
    },
    async created() {
      if (this.$props._extension) {
        this.$data.extension = this.$props._extension;
        } else {
             try {
                 this.$data.extension = await this.$session.get_item(`/extensions/${this.$props.id}`);
             } catch (err) {
                 console.log(err);
                 this.$data.alert = l.t('app.generic-error', 'Something went wrong');
                 return this.$data.loading = false;
             }
        }
        if (this.$props.generic_device_option) {
            this.$data.extension.add_generic_device = true;
        }
        // greetings enable checkboxes
        this.$data.greetings_enabled.name = !!this.$data.extension.name_greeting;
        this.$data.greetings_enabled.standard = !!this.$data.extension.voicemail.greeting.standard;
        this.$data.greetings_enabled.alternate = !!this.$data.extension.voicemail.greeting.alternate;

        try {
           if (this.$data.extension.caller_id && this.$data.extension.caller_id !== 'private' && !Number.isNaN(this.$data.extension.caller_id)) {
                const numbers = await this.$session.get_list(`/phone-numbers?mode=brief&filters[number]=${this.$data.extension.caller_id}`, 1);
                this.$data.extension.caller_id = numbers.items.length ? numbers.items[0] : null;
           }
        } catch (err) {
            this.$data.alert = {
                level: 'error',
                message: l.t('extensions.problems-finding-caller-id', 'Your caller ID can\'t be found.'),
            };
            setTimeout(() => {
                this.$data.alert = null
            }, 5000)
            this.$data.extension.caller_id = null;
        }

        return this.$data.loading = false;
    },
    methods: {
      async update(e) {
        if (e) e.preventDefault();
        if (!this.validate()) return false;
        this.prepareExtensionForUpdating();
        this.$emit('updateExtension', this.$data.extension);

        return true;
      },
      excluded_extensions_ids(items, obj_items) {
        return items.map((x) => 
            obj_items[x.id] &&
            obj_items[x.id].extension &&
            obj_items[x.id].extension.id !== this.$data.extension.id &&
            x.id
        ).filter((x) => x)
      },
      validate() {
        const messages = [];
        // eslint-disable-next-line no-console, no-useless-escape
        const regex = /^[\w\s\.,()-=]+$/;
        if (this.$data.extension.name && !regex.test(this.$data.extension.name)) {
            messages.push(l.t('extensions.bad-characters', 'Extension name doesn\'t have proper format. Allowed non-aplhanumeric characters: .,()-='));
        }

        if (this.$data.extension.voicemail.transcription === 'human') {
            messages.push(l.t('extensions.human-transcription-depricated', 'You use human assisted transcriptions that has been depricated. Please change it.'));
        }

        if (messages.length) {
            window.scroll({ top: 0 });
            this.alert = {
                message: messages.join('\n'),
                level: 'error',
            };
            setTimeout(() => {
                this.$data.alert = null
            }, 5000)        }
        return !this.alert;
      },
      prepareExtensionForUpdating() {
        // voicemail password
        if (typeof this.$data.extension.voicemail.password === 'number') {
          this.$data.extension.voicemail.password = this.$data.extension.voicemail.password.toString();
        }

        // voicemail emails notifications
        if (
          this.$data.extension.voicemail.notifications
          && this.$data.extension.voicemail.notifications.emails
        ) {
          this.$data.extension.voicemail.notifications.emails = this.$data.extension.voicemail.notifications.emails.filter(
            (x) => x !== '',
          );
        }

        // alternate greeting
        if (this.$data.extension.voicemail.greeting.alternate) {
          this.$data.extension.voicemail.greeting.alternate = {
            id: this.$data.extension.voicemail.greeting.alternate.id,
          };
        } else {
          this.$data.extension.voicemail.greeting.alternate = null;
        }
        if (!this.$data.greetings_enabled.alternate) {
          this.$data.extension.voicemail.greeting.alternate = null;
        }

        // standard greeting
        if (this.$data.extension.voicemail.greeting.standard) {
          this.$data.extension.voicemail.greeting.standard = {
            id: this.$data.extension.voicemail.greeting.standard.id,
          };
        } else {
          this.$data.extension.voicemail.greeting.standard = null;
        }
        if (!this.$data.greetings_enabled.standard) {
          this.$data.extension.voicemail.greeting.standard = null;
        }
        if (this.$data.extension.name_greeting) {
          this.$data.extension.name_greeting = {
            id: this.$data.extension.name_greeting.id,
          };
        } else {
          this.$data.extension.name_greeting = null;
        }
        if (!this.$data.greetings_enabled.name) {
          this.$data.extension.name_greeting = null;
        }
        // caller_id
          if (!this.$data.extension.caller_id || this.$data.extension.caller_id === 'private') {
              this.$data.extension.caller_id = 'private';
            } else if (typeof this.$data.extension.caller_id === 'object' && Object.prototype.hasOwnProperty.call(this.$data.extension.caller_id, 'phone_number')) {
            this.$data.extension.caller_id = this.$data.extension.caller_id.phone_number;
        } else {
            this.$data.extension.caller_id = null;
        }

        return true;
      },
      resetPassword() {
        if (!this.$data.extension.voicemail.enabled) return null;
        return (this.extension.voicemail.password = Math.floor(
          100000 + Math.random() * 900000,
        ));
      },
      voicemail_greeting_types() {
        const types = [];
        if (this.$data.extension.voicemail.greeting.type === null) types.push({value: null, text: ''});
        if (this.$data.extension.voicemail.greeting.standard) {
            types.push({value: 'standard', text: l.t('extensions.standard-greeting', 'Standard greeting')});
        }
        if (this.$data.extension.voicemail.greeting.alternate) {
            types.push({value: 'alternate', text: l.t('extensions.alternate-greeting', 'Alternate greeting')});
        }
        if (this.$data.extension.name_greeting) {
            types.push({value: 'name', text: l.t('extensions.recorded-name', 'Recorded name')});
        }

        return types;
      }
    },
    watch: {
      greetings_enabled: {
        deep: true,
        handler(greetings) {
            try {
                if (!greetings.standard) {
                  this.$data.extension.voicemail.greeting.standard = null;
                }
                if (!greetings.alternate) {
                  this.$data.extension.voicemail.greeting.alternate = null;
                }
                if (!greetings.name) {
                  this.$data.extension.name_greeting = null;
                }
            } catch (err) {
                console.log('Error using voicemail greetings.', err);
            }
        },
      },
    },
  };
</script>
