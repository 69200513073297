<template>
  <div v-if="autocomplete" class="api-autocomplete-container">
    <w-loader v-if="autocomplete.loading" :message="false" :no_margins="true"/>
    <div v-else class="d-flex">
        <w-autocomplete
            v-model="val"
            v-bind="$attrs"
            @blur="on_blur"
            ref="autocomplete"
            :items="items"
            :maxlength="autocomplete.mode.maxlength"
            :disabled="$attrs.disabled || autocomplete.loading || autocomplete.search_loading || playing"
            :loading="autocomplete.search_loading"
            :search-input.sync="search"
            :placeholder="placeholder"
            @change="(v) => $emit('change', v)"
            class="api-autocomplete"
            hide-details="auto"
            :data-test-id="$attrs['data-test-id'] || 'autocomplete'"
        >
            <template v-slot:no-data>
                <w-loader class="pa-3" v-if="autocomplete.search_loading" size="small" :message="false" />
                <div class="pa-3" v-else>{{l.t('app.no-items-available', 'No items available')}}</div>
            </template>
        </w-autocomplete>
        <slot></slot>
        <MoreOptions
            v-if="items.length && more_options.length > 1"
            :options="more_options"
            :activator_classes="'input-helper-btn'"
            data-test-id="api-autocomplete-actions"
        />
        <w-btn
            v-else-if="more_options.length === 1"
            :height="helper_button_size"
            :width="helper_button_size"
            :class="`${more_options[0].icon !== '$vuetify.icons.trash' ? 'action lighten-1' : ''}`"
            @click="more_options[0].cb()"
            class="input-helper-btn"
            icon
        >
          <v-icon :color="more_options[0].icon === '$vuetify.icons.trash' ? 'error' : 'text'" size="18">{{more_options[0].icon}}</v-icon>
        </w-btn>
        <Player
              v-if="val && autocomplete.mode.playable && !no_play && val !== 11"
              :just_player="true"
              :url="`${autocomplete.mode.playable}/${val}/download`"
              :key="val"
              :gray="true"
              :btn_size="32"
              :icon_size="18"
              @opened="playing = true"
              @stop="playing = false"
              class="my-auto"
              data-test-id="api-autocomplete-player"
        />
        <FormModal
            v-if="user_may_edit_resource"
            v-model="show_modal"
            :with_x="true"
            @close="show_modal = false"
            width="1000"
        >
            <div v-if="show_modal">
                <Media
                    v-if="['hold_music', 'greetings', 'user_hold_music'].includes(mode)"
                    :id="edit_id_val()"
                    @click:cancel="show_modal = false"
                    @updated="update_screen"
                    :withPlayer="true"
                    data-test-id="autocomplete-edit-media"
                />
                <MultiResourceModalContent
                    v-if="['extensions', 'virtualExtensions'].includes(mode)"
                    :id="edit_id_val()"
                    @click:cancel="show_modal = false"
                    type="extension"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-extension"
                />
                <Trunk
                    v-if="mode === 'trunks'"
                    @click:cancel="show_modal = false"
                    :id="edit_id_val()"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-trunk"
                />
                <Queue
                    v-if="mode === 'queues'"
                    :id="edit_id_val()"
                    @click:cancel="show_modal = false"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-queue"
                />
                <Schedule
                    v-if="mode === 'schedules'"
                    :id="edit_id_val()"
                    @updated="update_screen"
                    @click:cancel="show_modal = false"
                    data-test-id="autocomplete-edit-schedule"
                />
                <Menu
                    v-if="mode === 'menus'"
                    :id="edit_id_val()"
                    @updated="update_screen"
                    @click:cancel="show_modal = false"
                    :full_menu="true"
                    data-test-id="autocomplete-edit-menu"
                />
                <MultiResourceModalContent
                    v-if="['routes', 'presets'].includes(mode)"
                    :id="edit_id_val()"
                    type="route"
                    @click:cancel="show_modal = false"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-route"
                />
                <MultiResourceModalContent
                    v-if="mode === 'phone_numbers'"
                    :id="edit_id_val()"
                    @click:cancel="show_modal = false"
                    type="phone_number"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-phone-num"
                />
                <LiveReceptionistShow
                    v-if="mode === 'live_answer'"
                    :_id="edit_id_val()"
                    @click:cancel="show_modal = false"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-lr"
                />
                <Contact
                    v-if="mode === 'contacts' && autocomplete.extension"
                    :id="edit_id_val()"
                    :extension_id="autocomplete.extension"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-contact"
                />
                <ContactGroup
                    v-if="mode === 'groups' && autocomplete.extension"
                    :id="edit_id_val()"
                    :extension_id="autocomplete.extension"
                    @updated="update_screen"
                    data-test-id="autocomplete-edit-group"
                />
            </div>
        </FormModal>
    </div>
  </div>
</template>
<script>
import {vueComponent} from 'helpers';

import l from '../../../libs/lang';
import Autocomplete from '../../../models/Autocomplete';
import Player from '../Player.vue';
import MoreOptions from '../MoreOptions.vue';
import FormModal from '../modal/FormModal.vue';

export default {
    name: 'ApiAutocomplete',
    props: {
        value: {
            required: false,
        },
        mode: {
            requried: true,
        },
        value_prop: {
            type: String,
            default: 'id',
        },
        return_object: {
            type: Boolean,
            default: false,
        },
        extension: {
            type: [Number, String],
        },
        options_list: {
            type: Array,
            default: () => [],
            validator: (val) => val.every((x) => ['remove'].includes(x))
        },
        no_update: {
            type: Boolean,
            default: false,
        },
        no_play: {
            type: Boolean,
            default: false,
        },
        before_list: {
            type: Array,
        },
        after_list: {
            type: Array,
        },
        additional_options: {
            type: Array,
        },
        filtered_values: {
            type: [Array, Function], // array of ids or cb returing array of ids
        },
        readonly: {
            type: Boolean
        }
    },
    components: {
        Player,
        FormModal,
        MoreOptions,
        LiveReceptionistShow: () => import('live-receptionist-components/components/screens/show.vue'),
        MultiResourceModalContent: () => import('../../elements/MultiResourceModalContent.vue'),
        Menu: () => import('../../screens/menus/show.vue'),
        Media: () => import('../../screens/media/show.vue'),
        Trunk: () => import('../../screens/trunks/show.vue'),
        Queue: () => import('../../screens/queues/show.vue'),
        Contact: () => import('../../screens/contacts/show.vue'),
        Schedule: () => import('../../screens/schedules/show.vue'),
        ContactGroup: () => import('../../screens/groups/show.vue'),
        Extension: () => import('../../screens/extensions/show.vue'),
        PhoneNumber: () => import('../../fragments/PhoneNumberSettings.vue'),
    },
    data() {
      return {
        l,
        val: this.$props.value,
        prev_val: null,
        items: [],
        search: '',
        autocomplete: null,
        show_modal: false,
        playing: false,
        helper_button_size: 32,
      };
    },
    async created() {
        if (this.$data.val && this.$props.return_object) {
            this.$data.val = this.$data.val[this.$props.value_prop];
        }
        this.$data.autocomplete = new Autocomplete(
            this.$props.mode,
            this.$session,
            this.$props.extension,
            {
                multiple: this.multiple,
                value: this.$data.val,
                reload: this.$props.reload,
                value_prop: this.$props.value_prop,
                return_object: this.$props.return_object,
            },
            vueComponent(this),
        );
        await this.$data.autocomplete.load_items();
        this.generateItems();
        if (
            this.$data.val
            && this.$data.autocomplete.mode.search_missing_value
            && !this.$data.items.find((x) => x.value === this.$data.val)
            && this.$props.value_prop === 'id'
        ) { // ex. the value is an extension that is deleted in a meanwhile, we searched for it and didn't find it
            this.$data.val = null   
        }
    },
    methods: {
        generateItems() {
            let items = this.$data.autocomplete.autocomplete_items;
            if (Array.isArray(this.$props.before_list)) {
                items = this.filter_items(this.$props.before_list, items);
                items = this.$props.before_list.concat(items);
            }
            if (Array.isArray(this.$props.after_list)) {
                items = this.filter_items(this.$props.after_list, items);
                items = items.concat(this.$props.after_list);
            }
            if (Array.isArray(this.$props.filtered_values) && this.$props.filtered_values.length) {
                items = this.filter_items(this.$props.filtered_values, items);
            } else if (typeof this.$props.filtered_values === 'function') {
                items = this.filter_items(this.$props.filtered_values(items, this.autocomplete.response_items_as_object), items);
            }

            this.$data.items = items;
        },
        filter_items(arr, items) {
            let values = arr.map((x) => {
                if (x && x.value && typeof x.value === 'object') {
                    return x.value[this.$props.value_prop];
                }
                return x;
            })
            values = [...new Set(values)];
            return items.filter((x) => {
                if (x && x.value && typeof x.value === 'object' && this.$props.return_object) {
                    return !values.includes(x.value[this.$props.value_prop])
                }
                return !values.includes(x.value)
            });
        },
        edit_id_val() {
            const value = this.multiple ? this.$data.val[0] : this.$data.val;
            const obj_id = this.items.find((x) => x.value === value).id; // value_prop can be different so ID is not always the value
            if (obj_id) return obj_id;
            const int_value = this.items.find((x) => x.value === value); // can be part of before/after_list
            if (int_value) return int_value.value;

            return null;
        },
        async update(data) {
            this.$data.autocomplete.loading = true;
            this.$data.show_modal = false;
            if (this.autocomplete.extension) this.autocomplete.mode.model.extension = this.autocomplete.extension;
            await this.autocomplete.mode.model.update(data);
            if (this.autocomplete.mode.model.alert) {
                this.$data.autocomplete.loading = false;
                this.alert = this.autocomplete.mode.model.alert;
                setTimeout(() => this.alert = null, 3000);
                if (this.alert.level === 'error') {
                    this.$data.autocomplete.loading = false;
                    return false;
                }
            }
            this.update_screen(data);
            return this.$data.autocomplete.loading = false;
        },
        update_screen(data) {
            if (data && this.$data.autocomplete) {
                const target_index = this.$data.autocomplete.response.items.findIndex((x) => x.id === data.id);
                if (target_index > -1) {
                    this.$data.autocomplete.response.items[target_index] = data;
                    this.$data.autocomplete.mode.model.updateCache(data, this.autocomplete.mode_name);
                }
            }
            setTimeout(() => {
                this.$data.show_modal = false;
            }, 1000);
            this.$data.items = this.$data.autocomplete.autocomplete_items;
        },
        on_blur() {
            if (!this.$data.val && this.$data.prev_val && !this.$data.items.find((x) => x.value === this.$data.val)) { // on blur when user deleted the input, ex. contacts extension selector
                this.$data.val = this.$data.prev_val;
            }
        },
    },
    watch: {
        async search(v) {
            if (v) {
                const texts = this.items.map((x) => x.text).filter((x) => x);
                if (!texts.find((x) => x.includes(v))) {
                    await this.$data.autocomplete.search(v);
                }
            }
            this.generateItems();
        },
        val(v, prev_val) {
            if (prev_val) this.$data.prev_val = prev_val;
            if (!v && !this.$data.items.find((x) => x.value === v)) { // on user deletion of the input, ex. contacts extension selector
                return null;
            }
            if (this.$refs.autocomplete) {
                const el = this.$refs.autocomplete.$el.querySelector('input');
                if (el) el.blur();
            }
            if (this.$data.autocomplete && this.items.length) {
                let value;
                if (this.$props.before_list && this.$props.before_list.find((x) => x.value === v)) {
                    value = v;
                } else if (this.$props.after_list && this.$props.after_list.find((x) => x.value === v)) {
                    value = v;
                } else if (this.$props.return_object) {
                    value = this.autocomplete.response.items.find((x) => x[this.$props.value_prop] === v);
                    if (!value) {
                        value = {};
                        value[this.$props.value_prop] = v;
                    }
                } else {
                    value = v;
                }
                this.$emit('input', value);
            }
        }
    },
    computed: {
        multiple() {
            return !!Object.prototype.hasOwnProperty.call(this.$attrs, 'multiple');
        },
        user_may_edit_resource() {
            const may_edit = this.$data.val
                && this.edit_enabled_on_app_level
                && !this.$props.no_update
                && (!this.multiple || (this.multiple && this.$data.val.length === 1))
                && !this.$attrs.disabled
                && this.$data.autocomplete.mode.edit_route
                && this.$branding.data.routes.includes(this.$data.autocomplete.mode.edit_route)
                && this.$data.autocomplete.mode.model;
            if (!may_edit) return false;

            if (['hold_music', 'greetings', 'user_hold_music'].includes(this.$props.mode)) {
                return this.$data.val && this.$data.val > 24;
            }

            return this.$data.val !== 'create';
        },
        more_options() {
            let options = [];
            if (this.user_may_edit_resource) {
                options.push({
                    title: l.t('app.edit', 'Edit'),
                    icon: '$vuetify.icons.edit',
                    cb: () => this.$data.show_modal = true,
                });
            }
            if (Array.isArray(this.$props.additional_options) && !this.$props.readonly) {
                options = [
                ...options,
                ...this.$props.additional_options
                ];
            }
            if (this.$props.options_list && this.$props.options_list.includes('remove') && !this.$props.readonly) {
                options.push({
                    title: l.t('app.remove', 'Remove'),
                    icon: '$vuetify.icons.trash',
                    cb: () => {
                        this.$emit('click:remove');
                        this.$global_emitter.$emit('form_input_changed');
                    },
                });
            }
            return options;
        },
        placeholder() {
            if (
                !this.$attrs.label
                && (this.$attrs.placeholder || this.$data.autocomplete.mode.placeholder)
                && this.items.length
                && (!this.multiple || (this.multiple && !this.$data.val.length))
            ) {
                if (this.$attrs.placeholder && typeof this.$attrs.placeholder === 'string') return this.$attrs.placeholder;

                return this.$data.autocomplete.mode.placeholder;
            }

            return '';
        },
        edit_enabled_on_app_level() {
            const enabled_on_app_lvl = process.env.REACT_APP_SHOW_AUTOCOMPLETE_EDIT
            return enabled_on_app_lvl === true ||
            (
                typeof enabled_on_app_lvl === 'string' &&
                enabled_on_app_lvl.toLowerCase() === 'true'
            )
        }
    },
  };
</script>
