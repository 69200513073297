/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { PaymentMethodsProvider, usePaymentMethodContext, PaymentMethodContextDataType } from './PaymentMethodsProvider'
import { StatementsProvider, useStatementsContext, StatemenetsContextDataType } from './StatementsProvider'
import { usePhoenixSessionContext } from '../PhoenixSession/PhoenixSessionProvider'
import { getInvoiceDetails as v5GetInvoiceDetails } from './api_v5'
import { InvoiceDetailsType } from 'interfaces'
import { userMayAccessBilling } from './helpers'

type BillingContextDataType = {
    getData: () => Promise<boolean>,
    invoiceDetails: InvoiceDetailsType,
    reloadInvoiceDetails: () => Promise<void>,
    // payment-methods
    stripe: any,
    loadStripe: () => Promise<any>,
    paymentMethodsItems: PaymentMethodContextDataType['items'],
    paymentMethodsCreate: PaymentMethodContextDataType['create'],
    paymentMethodsRemove: PaymentMethodContextDataType['remove'],
    paymentMethodsGetProviderData: PaymentMethodContextDataType['getProviderData'],
    paymentMethodsPrimary: PaymentMethodContextDataType['primary'],
    paymentMethodsSetStatus: PaymentMethodContextDataType['setStatus'],
    paymentMethodsGetData: PaymentMethodContextDataType['getData'],
    // statements
    statementsGetPdfBlob: StatemenetsContextDataType['getPdfBlob'],
    statementsItems: StatemenetsContextDataType['items'],
    statementsGetData: StatemenetsContextDataType['getData'],
    statementsGetPdfLink: StatemenetsContextDataType['getInvoicePdfLink'],
    statementsGetFileName: StatemenetsContextDataType['getFileName'],
    statementsTimeFormat: StatemenetsContextDataType['statementsTimeFormat'],
    statementDownloadPdf: StatemenetsContextDataType['downloadPdf'],
}

const BillingContext = createContext<BillingContextDataType | undefined>(undefined)

/**
 *
 */
const BillingProviderComponent = ({ children }: PropsWithChildren<any>): JSX.Element => {
    const {
        items: paymentMethodsItems,
        create: paymentMethodsCreate,
        remove: paymentMethodsRemove,
        getProviderData: paymentMethodsGetProviderData,
        primary: paymentMethodsPrimary,
        setStatus: paymentMethodsSetStatus,
        loadStripe,
        stripe,
        getData: paymentMethodsGetData
    } = usePaymentMethodContext()
    const {
        getPdfBlob: statementsGetPdfBlob,
        items: statementsItems,
        getData: statementsGetData,
        getInvoicePdfLink: statementsGetPdfLink,
        getFileName: statementsGetFileName,
        statementsTimeFormat,
        downloadPdf: statementDownloadPdf
    } = useStatementsContext()

    const [invoiceDetails, setInvoiceDetails] = useState(null)

    const { session } = usePhoenixSessionContext()

    const reloadInvoiceDetails = async (): Promise<void> => {
        const userMayAccess = await userMayAccessBilling(session)
        if (!userMayAccess) return null

        const invoiceDetailsReponse = await v5GetInvoiceDetails()
        const modifiedInvoiceDetailsResponse = Object.fromEntries(
            Object.entries(invoiceDetailsReponse).map(([key, value]) => {
                if (typeof value === 'string' && value.includes('CR')) {
                    return [key, value.replace('CR', ' CR')]
                }
                return [key, value]
            })
        )
        setInvoiceDetails(modifiedInvoiceDetailsResponse)
    }

    const getInvoiceDetails = async () => {
        const userMayAccess = await userMayAccessBilling(session)
        if (!userMayAccess) return false

        if (invoiceDetails) return invoiceDetails
        await reloadInvoiceDetails()
    }

    const getData = async (): Promise<boolean> => {
        const userMayAccess = await userMayAccessBilling(session)
        if (!userMayAccess) return false

        await Promise.all([
            getInvoiceDetails(),
            paymentMethodsGetData(),
            statementsGetData()
        ])
        return true
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <BillingContext.Provider value={{
            // billing
            getData,
            invoiceDetails,
            reloadInvoiceDetails,
            // payment-methods
            stripe,
            loadStripe,
            paymentMethodsItems,
            paymentMethodsCreate,
            paymentMethodsRemove,
            paymentMethodsGetProviderData,
            paymentMethodsPrimary,
            paymentMethodsSetStatus,
            paymentMethodsGetData,
            // statements
            statementsGetPdfBlob,
            statementsItems,
            statementsGetData,
            statementsGetPdfLink,
            statementsGetFileName,
            statementsTimeFormat,
            statementDownloadPdf
        }}>
            {children}
        </BillingContext.Provider>
    )
}

/**
 *
 */
const BillingProvider = (props) => {
    return <PaymentMethodsProvider>
        <StatementsProvider>
            <BillingProviderComponent {...props} />
        </StatementsProvider>
    </PaymentMethodsProvider>
}

/**
 *
 */
const useBillingContext = () => {
    const data = useContext(BillingContext)
    if (data === undefined) {
        throw new Error('useBillingContext must be used inside <BillingPRovider />')
    }

    return { ...data }
}

/**
 *
 */
export { BillingContext, BillingProvider, useBillingContext }
