/* eslint-disable @typescript-eslint/naming-convention */
import { loadStripe as doLoadStripe } from '@stripe/stripe-js'
import l from 'lang'
import React, { createContext, useContext, useState, useMemo, PropsWithChildren } from 'react'
import { usePhoenixSessionContext } from '../PhoenixSession/PhoenixSessionProvider'
import { ResponseType as V4ResponseType, PaymentMethodType, PaymentMethodStatusType } from 'interfaces'
import { ControlledError } from 'errors'
import { userMayAccessBilling } from './helpers'

// import mockPMethods from 'test-helpers/src/static-values/api/responses/v4/payment-methods'
// const apidata = mockPMethods.list

type PaymentMethodContextDataType = {
    items: PaymentMethodType[],
    create: (
        cardElement: Record<string, unknown>,
        opts?: Record<string, unknown>,
        isPrimary?: boolean,
        nickname?: string
    ) => Promise<PaymentMethodType>,
    remove: (id: number) => Promise<boolean>,
    getProviderData: () => Promise<any>,
    primary: PaymentMethodType,
    setStatus: (
        id: number,
        status: PaymentMethodStatusType
    ) => Promise<boolean>,
    setStripe: any,
    loadStripe: () => Promise<any>,
    stripe: any,
    getData: () => Promise<void>,
}

const PaymentMethodsContext = createContext<PaymentMethodContextDataType | undefined>(undefined)

/**
 *
 */
const PaymentMethodsProvider = ({ children }: PropsWithChildren<any>): JSX.Element => {
    const [stripe, setStripe] = useState(null)
    const [apiResponse, setApiResponse] = useState(null)

    const { session } = usePhoenixSessionContext()

    const items = useMemo(
        () => apiResponse && apiResponse.items,
        [apiResponse]
    )

    const primary = useMemo(
        (): PaymentMethodType => apiResponse && apiResponse.items.find((x) => x.status === 'primary'),
        [apiResponse]
    )

    const loadItems = async (): Promise<V4ResponseType<PaymentMethodType>> => {
        const userMayAccess = await userMayAccessBilling(session)
        if (!userMayAccess) return null

        // const data = apidata
        const data = await session.get_list_all('/payment-methods?with_details=1')
        data.items.sort((a, b) => {
            if (a.status === 'primary' && b.status !== 'primary') {
                return -1
            }
            return 0
        })
        return data
    }

    const getProviderData = async () => {
        const jsonData = await fetch(`${session._phoenix_url('/v4/credit-cards/provider', true)}`)
        return jsonData.json()
    }

    const loadStripe = async () => {
        if (stripe) return stripe
        const providerData = await getProviderData()

        if (providerData.provider !== 'stripe') {
            throw new ControlledError(l.t('pm.bad-provider', 'Bad provider'))
        }
        const loadedStripe = await doLoadStripe(providerData.public_key)
        setStripe(loadedStripe)
        // this.stripe = await loadStripe('pk_test_qblFNYngBkEdjEZ16jxxoWSM'); // cant work without apropriate server side secret
        return loadedStripe
    }

    const createTemporaryCCToken = async (token) => {
        const response = await fetch(`${session._phoenix_url('/v4/credit-cards', true)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ source: token })
        })

        return response.json()
    }

    const create = async (cardElement, opts = {}, isPrimary, nickname): Promise<PaymentMethodType> => {
        const options = { currency: 'usd', ...opts }
        let pMethod = null
        if (!stripe) throw new ControlledError('Stripe is not loaded')
        const result = await stripe.createToken(cardElement, options)
        if (result.error) {
            throw new ControlledError(result.error.message)
        }

        const tempToken = await createTemporaryCCToken(result.token.id)
        if ('@error' in tempToken) {
            throw new ControlledError(tempToken['@error']['@message'])
        }

        pMethod = await session.create_item(
            '/payment-methods',
            {
                type: 'cc',
                cc_token: tempToken.token,
                status: isPrimary ? 'primary' : undefined,
                nickname: nickname || ''
            }
        )

        loadItems().then((res) => setApiResponse(res))

        return pMethod
    }

    const setStatus = async (id, status) => {
        // await new Promise(r => setTimeout(r, 1000))
        // setApiResponse({
        //     ...apiResponse,
        //     items: apiResponse.items.map((x) => {
        //         x.status = x.id === id ? 'primary' : 'onfile'
        //         return x
        //     })
        // })
        // return true
        await session.patch_item(`/payment-methods/${id}`, { status })
        const items = await loadItems()
        setApiResponse(items)
        return true
    }

    const remove = async (id) => {
        if (items && items.length < 2) {
            throw new ControlledError(l.t('pm.at-least-1-payment-method', 'You must have at least 1 payment method on file.'))
        }
        await session.delete_item(`/payment-methods/${id}`)
        setApiResponse({
            ...apiResponse,
            total: apiResponse.total - 1,
            items: apiResponse.items.filter((x) => x.id !== id)
        })

        return true
    }

    const getData = async () => {
        if (items) return items
        setApiResponse(await loadItems())
    }

    return (
        <PaymentMethodsContext.Provider value={{
            items,
            create,
            remove,
            getProviderData,
            primary,
            setStatus,
            loadStripe,
            stripe,
            getData

        }}>
            {children}
        </PaymentMethodsContext.Provider>
    )
}

/**
 *
 */
const usePaymentMethodContext = () => {
    const pMethodData = useContext(PaymentMethodsContext)
    if (pMethodData === undefined) {
        throw new Error('usePaymentMethodContext must be used inside PaymentMethodsProvider tags')
    }

    return { ...pMethodData }
}

/**
 *
 */
export { PaymentMethodsContext, PaymentMethodsProvider, usePaymentMethodContext, PaymentMethodContextDataType }
