/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useContext, useState } from 'react'
import Session from 'phoenix-api-js-client'
import { get_phoenix_session } from 'phoenix-session-helpers'
import Cachier from 'cachier'

interface PhoenixSessionContextProps {
  session: Session
}

/**
 *
 */
export const PhoenixSessionContext = createContext<PhoenixSessionContextProps | undefined>(undefined)

/**
 *
 */
export const PhoenixSessionProvider: React.FC = ({ children }) => {
    const [session, setSession] = useState(get_phoenix_session())

    session.on('logged-in', () => setSession(get_phoenix_session()))
    session.on('logged-out', () => setSession(get_phoenix_session()))
    session.on('logging-out', () => {
        if (session.user) {
            const cachier = new Cachier(session.user.id)
            cachier.purgeCache()
        }

        setSession(get_phoenix_session())
    })

    return (
        <PhoenixSessionContext.Provider value={{ session }}>
            {children}
        </PhoenixSessionContext.Provider>
    )
}

/**
 *
 */
export const usePhoenixSessionContext = () => {
    const phoenixSession = useContext(PhoenixSessionContext)
    if (phoenixSession === undefined) {
        throw new Error('usePhoenixSessionContext must be used inside PhoenixSessionProvider tags')
    }

    return { ...phoenixSession }
}
