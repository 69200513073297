/**
 *
 */
export default class ControlledError extends Error {
    /**
     *
     */
    get suppressed () {
        return true
    }
}
