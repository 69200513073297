import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
    static loadContacts = async (filters = {}, limit = 20, cursor = null) => {
        await getPhoneCom()
        const data = { filters, limit }
        if (cursor) data.cursor = cursor
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/load-contacts`
        const response = await ajax.postAsGet(requestUrl, data)

        if (response.message === 'Network Error') return 'network-error'

        return response.data || {
            total: 0,
            limit: limit,
            items: [],
            group_types: []
        }
    }

    static loadContact = async (contactId) => {
        await getPhoneCom()
        const data = { filters: { id: contactId }, limit: 1, cursor: null }
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/load-contacts`
        const response = await ajax.post(requestUrl, data)

        if (response.message === 'Network Error') return 'network-error'

        return response.data.items[0]
    }

    static createContact = async data => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/create-contact`
        return (await ajax.post(requestUrl, data)).data
    }

    static updateContact = async data => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/update-contact`
        return (await ajax.post(requestUrl, data)).data
    }

    static deleteContact = async contact_id => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/contacts/delete-contact`
        return await ajax.post(requestUrl, { contact_id })
    }

    static listInboxes = async (filters, type = 'all', cursor = null, limit = 25, sort = { created_at: 'desc' }, fullObject = true) => {
        const url = `${PhoneComUser.getv5ApiRoot()}/inbox/list-inbox`
        const headers = { 'Content-Type': 'application/json' }
        headers.Authorization = `CP ${window.V5PHONECOM.cp_token}`
        const fetchCredentials = 'omit'
        const data = {
            account_id: window.V5PHONECOM.voip_id,
            extension_id: window.V5PHONECOM.voip_phone_id,
            type,
            filters,
            limit,
            sort
        }
        if (cursor) data.cursor = cursor
        if (fullObject === true) data.full_object = true

        const itemsFetched = await fetch(url, {
            method: 'POST',
            headers: headers,
            credentials: fetchCredentials,
            body: JSON.stringify(data)
        })

        const itemsJson = await itemsFetched.json()
        return itemsJson
    }

    // Voicemail

    static getMusicOnHoldLink = async (voip_recording_id, trackOffset = 0) => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
        // eslint-disable-next-line camelcase
        const response = await ajax.post(requestUrl, { voip_recording_id, track_offset: trackOffset })
        return response.data
    }

    static createFileGreeting = async (params) => {
        const { name, file, generateCallMenuAudio, prependSilence, appendSilence, extensionId, id } = params
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/${id ? 'update' : 'create'}-greeting`
        const data = { name, file }
        if (id) data.voip_recording_id = id
        if (generateCallMenuAudio) data.generate_call_menu_audio = generateCallMenuAudio
        if (prependSilence) data.prepend_silence = prependSilence
        if (appendSilence) data.append_silence = appendSilence
        if (extensionId) data.extension_id = extensionId
        const setContentTypeFunctions = {
            mp3: () => (data.content_type = 'audio/mpeg'),
            wav: () => (data.content_type = 'audio/wav'),
            ulaw: () => (data.content_type = 'audio/basic')
        }
        setContentTypeFunctions[name.split('.')[name.split('.').length - 1]]?.()
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static createFileMusicOnHold = async (params) => {
        const { name, file, extensionId } = params
        await getPhoneCom()
        const data = { name, file }
        if (extensionId) data.extension_id = extensionId
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-music-on-hold`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    /**
     * @param {number} userId the user id to mark completed for | will pull from phonecom object if left empty
     * @param {string} wizardType the wizard type default {company_setup}
     */
    static markWizardComplete = async (userId = null, wizardType = 'company_setup') => {
        const phoneCom = await getPhoneCom()
        if (!userId) {
            userId = phoneCom?.user_id
        }
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/setup-wizard/mark-wizard-completed`
        return ajax.post(requestUrl, { user_id: userId, wizard_type: wizardType }).then(response => response?.data)
    }

    static createTTSGreeting = async (params) => {
        const { text, voice, name, generateCallMenuAudio, prependSilence, appendSilence, extensionId, id } = params
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/${id ? 'update' : 'create'}-greeting`
        // eslint-disable-next-line
        const data = { origin: 'tts', tts_text: text, tts_voice: voice, name }
        if (id) data.voip_recording_id = id
        // eslint-disable-next-line
        if (generateCallMenuAudio) data.generate_call_menu_audio = generateCallMenuAudio
        // eslint-disable-next-line
        if (prependSilence) data.prepend_silence = prependSilence
        // eslint-disable-next-line
        if (appendSilence) data.append_silence = appendSilence
        // eslint-disable-next-line
        if (extensionId) data.extension_id = extensionId
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static updateTTSGreeting = async (params) => {
        const { text, voice, name, generateCallMenuAudio, prependSilence, appendSilence, extensionId, id } = params
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/update-greeting`
        // eslint-disable-next-line
        const data = { origin: 'tts', tts_text: text, tts_voice: voice, name, voip_recording_id: id }
        // eslint-disable-next-line
        if (generateCallMenuAudio) data.generate_call_menu_audio = generateCallMenuAudio
        // eslint-disable-next-line
        if (prependSilence) data.prepend_silence = prependSilence
        // eslint-disable-next-line
        if (appendSilence) data.append_silence = appendSilence
        // eslint-disable-next-line
        if (extensionId) data.extension_id = extensionId
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static ttsToAudio = async (text) => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'tools')}/storage/tts-to-audio`
        const response = await ajax.post(requestUrl, { text })
        return response.data
    }

    static createTTSMusicOnHold = async (params) => {
        const { text, voice, name, extensionId } = params
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-music-on-hold`
        // eslint-disable-next-line
        const data = { origin: 'tts', tts_text: text, tts_voice: voice, name }
        // eslint-disable-next-line
        if (extensionId) data.extension_id = extensionId
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static getVoicemailConfig = async (extensionId) => {
        const data = extensionId ? { extension_id: extensionId } : {}
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static configureVoicemail = (voip_recording_id, extension_id) => {
        if (!extension_id) extension_id = PhoneComUser.getExtensionId()
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
            return ajax.post(requestUrl, { voip_recording_id, extension_id }).then(response => response.data)
        })
    }

    static sendMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/send-message', payload)
            .then(response => response)
    }

    static registerSipDevice = async () => {
        await getPhoneCom()
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/register-sip-device`
        return await ajax.post(requestUrl, {})
    }

    // Company extension
    static getCompanyExtension = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/extension/get-team-extension`
        const response = await ajax.post(requestUrl, {})
        const extension = (response && response.data && response.data.length) ? response.data[0] : {}
        extension.id = extension.extension_id
        delete extension.extension_id
        return extension
    }

    static getExtensionsCallerIds = async (extensionId, teamExtensionIds) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/phone-numbers/list-phone-numbers`
        const response = await ajax.post(requestUrl, { extension_id: extensionId, team_extension_ids: teamExtensionIds })
        return response.data
    }

    static getUserPhoneNumbers = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/list-phone-numbers`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static getUserCallingPhoneNumbers = async () => {
        const response = await this.getUserPhoneNumbers()
        return response?.calling
    }

    static getUserMessagingPhoneNumbers = async () => {
        const response = await this.getUserPhoneNumbers()
        return response?.messaging
    }

    static getUserFaxPhoneNumbers = async () => {
        const response = await this.getUserPhoneNumbers()
        return response?.fax
    }

    static getAccountHoldMusic = () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-account-hold-music`
        return ajax.postAsGet(requestUrl, {}).then(response => response.data)
    }

    static getAccountHasProUser = () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/has-pro-user`
        return ajax.post(requestUrl, {}).then(response => response?.data?.message)
    }

    static updatePhoneNumber = async (payload) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-phone-number`
        return await ajax.post(requestUrl, payload)
    }

    static configureCalling = async (numberType, data) => {
        data.number_type = numberType
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/user/configure-calling`
        const response = await ajax.post(requestUrl, data)
        if (response.errors) return response
        return response.data
    }

    /**
     * Load users
     *
     * @param {number} pageSize - or limit
     * @param {number} offset - offset
     * @returns {object} - users
     */
    static loadUsers = async (pageSize, offset) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const apiBase = process.env.REACT_APP_USER_API_URL
        const requestUrl = `${apiBase}/voip/${accountId}/users?page_size=${pageSize}&offset=${offset}&order_by=status.asc&include=extension,plan,add_ons,devices,numbers`
        const response = await ajax.get(requestUrl)
        if (response.message === 'Network Error') return []
        // TODO: temporarily update reponse.data.data to change user_plan_id from 4,5,6 to 1,2,3 respectively
        response.data.data.forEach((user) => {
            if ([4, 5, 6].includes(user.user_plan_id)) {
                user.user_plan_id = user.user_plan_id - 3
            }
        })
        return response.data.data
    }

    /**
     * update users
     *
     * @param {Array} users - list of user objects
     */
    static updateUsers = async (users) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const apiBase = process.env.REACT_APP_USER_API_URL
        const requestUrl = `${apiBase}/voip/${accountId}/users`
        const response = await ajax.put(requestUrl, users)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }
        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling update users endpoint', err)
            return { error: true }
        }
    }

    /**
     * Update user assigned phone number names to match the user's name
     *
     * @param {string} current_user_name - orginial name of user
     * @param {string} new_user_name - updated name of user
     * @param {int} user_extension_id - extension id related to the user
     */
    static updateAsignedNumberNames = async (current_user_name, new_user_name, user_extension_id) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/update-number-names`
        const data = { current_user_name, new_user_name, user_extension_id }
        const response = await ajax.post(requestUrl, data)
        return response
    }

    static getMainCompanyNumberVoipDidId = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/get-main-company-number`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static loadPhoneNumbers = async (cursor = null, filters = {}, limit = 20, sort = 'asc') => {
        const data = { filters, sort, limit }
        if (cursor) data.cursor = cursor
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/phone-numbers/list-phone-numbers-2-2`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static getExtensionRules = async extensionId => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/extension/get-extension-rules`
        const response = await ajax.post(requestUrl, { extension_id: extensionId })
        return response.data
    }

    static findAvailableNumbers = async (area_code = null, limit = 9) => {
        const numberManagerBase = process.env.REACT_APP_NUMBER_MANAGER_API_URL
        const requestUrl = `${numberManagerBase}/get-available-numbers`
        const payload = { limit, area_code }
        const response = await ajax.postAccount(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling get-available-number endpoint', err)
            return { error: true }
        }
    }

    static addNumberToAccountAndAssignToUser = async (numberId, userId) => {
        const numberManagerBase = process.env.REACT_APP_NUMBER_MANAGER_API_URL
        const requestUrl = `${numberManagerBase}/add-available-number`
        const payload = { search_result_id: numberId, voip_user_id: userId }
        const response = await ajax.postAccount(requestUrl, payload)
        if (response.response && response.response.status >= 400) {
            // Response is an axios error object - server returned a non-2xx response,
            // but we can return response payload

            return response.response.data
        }

        try {
            return response.data ? response.data : { error: true }
        } catch (err) {
            console.error('Error calling add-available-number endpoint', err)
            return { error: true }
        }
    }

    static loadSchedules = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/schedules/list-schedules`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static loadHolidays = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/schedules/list-holidays`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static updateSchedules = async (openHoursSchedule, lunchSchedule, holidaysSchedule, customHolidaysSchedule) => {
        const data = {
            open_hours_schedule: openHoursSchedule,
            lunch_schedule: lunchSchedule,
            holidays_schedule: holidaysSchedule,
            custom_holidays_schedule: customHolidaysSchedule
        }
        console.log('#### updateSchedules:', data)
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/schedules/update-schedules`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static getCompanySettings = async () => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-account-settings`
        const response = await ajax.post(requestUrl, {})
        return response.data
    }

    static setCompanySettings = async data => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/set-account-settings`
        const response = await ajax.post(requestUrl, data)
        return response.data
    }

    static setVoipPhoneExtension = async (voipPhoneId, extension) => {
        try {
            parseInt(extension)
        } catch (err) {
            throw new Error('Invalid extension value')
        }
        if (extension.length < 2) {
            throw new Error('This extension number is reserved for other services.')
        }
        const reservedExtensions = [411, 611, 711, 800, 911, 988]
        if (reservedExtensions.includes(parseInt(extension))) {
            if (parseInt(extension) === 988) {
                throw new Error('988 cannot be used as an extension number as it is a reserved dial out number.')
            } else {
                throw new Error('This extension number is reserved for other services.')
            }
        }

        const accountId = PhoneComUser.getAPIAccountId()
        // due to issues with v4 in stage this allows to override default v4 url
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/extensions/${voipPhoneId}`
        const payload = {
            extension: parseInt(extension)
        }
        const response = await ajax.patchClean(requestUrl, payload)
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const ret = response.data.extension
                return ret
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static getReceiptUrl = async faxId => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/download-receipt`
        const response = await ajax.post(requestUrl, { fax_id: faxId })
        if (response === 'no info found') return { error: response }
        return response.data
    }

    static createLiveAnswerScript = async (payload) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/live-answer`
        const response = await ajax.post(requestUrl, payload)
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const scriptId = response.data.id
                return scriptId
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static updateLiveAnswerScript = async (scriptId, payload) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/live-answer/${scriptId}`
        const response = await ajax.patchClean(requestUrl, payload)
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const scriptId = response.data.id
                return scriptId
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static deactivateLiveAnswerScript = async (scriptId) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/live-answer/${scriptId}`
        const response = await ajax.patchClean(requestUrl, { enabled: false })
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const scriptId = response.data.id
                return scriptId
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static deleteLiveAnswerScript = async (scriptId) => {
        const accountId = PhoneComUser.getAPIAccountId()
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/live-answer/${scriptId}`
        const response = await ajax.delete(requestUrl, {})
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const scriptId = response.data.id
                return scriptId
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static listLiveAnswerScripts = async () => {
        const accountId = PhoneComUser.getAPIAccountId()
        const v4Root = process.env.REACT_APP_V4_ROOT_OVERRIDE || PhoneComUser.getv4ApiRoot()
        const requestUrl = `${v4Root}/accounts/${accountId}/live-answer?limit=100`
        const response = await ajax.get(requestUrl)
        if (response.request &&
            response.status >= 200 &&
            response.status < 300) {
            try {
                const scripts = response.data
                return scripts
            } catch (err) {
                throw new Error('Invalid value returned')
            }
        }
        const errMsg = response?.response?.data?.['@error']?.['@message'] || 'Update request failed'
        throw new Error(errMsg)
    }

    static submitHubspotForm = async (hubspotFormConfig) => {
        // get name, email, phone, and company from window object
        const V5PHONECOM = window.V5PHONECOM
        if (!V5PHONECOM) throw new Error('Error submitting hubspot form')
        const email = V5PHONECOM.email
        const firstName = V5PHONECOM.first_name
        const lastName = V5PHONECOM.last_name
        const company = V5PHONECOM.company
        const number = V5PHONECOM.direct_number ? V5PHONECOM.direct_number : V5PHONECOM.phone_number[0]
        const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${hubspotFormConfig.portalId}/${hubspotFormConfig.formId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'firstname',
                        value: firstName
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'lastname',
                        value: lastName
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'company',
                        value: company
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'phone',
                        value: number
                    }
                ],
                context: {
                    pageUri: 'https://www.configure.phone.com',
                    pageName: 'Phone.com > Configure',
                    sfdcCampaignId: hubspotFormConfig.sfdcCampaignId
                }
            })
        })
        // catch error if response is not ok
        if (!response.ok) {
            throw new Error('Error submitting hubspot form')
        }
        return response.json()
    }
}

export default API
