/* eslint-disable @typescript-eslint/naming-convention */
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'
import { InvoiceDetailsType, InvoicesType, InvoiceLinkType } from 'interfaces'
/**
 *
 */
const getInvoiceDetails = async (): InvoiceDetailsType => {
    const phonecom = await getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot()}/billing/invoice-details`
    const response = await ajax.post(requestUrl, { voip_id: phonecom.voip_id })
    return response.data
}

/**
 *
 */
const getInvoices = async (): InvoicesType => {
    const phonecom = await getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice-list`
    const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id })
    return response.data
}

/**
 *
 */
const getInvoicePdfLink = async (invoice_id: number): InvoiceLinkType => {
    const phonecom = await getPhoneCom()
    const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice`
    const response = await ajax.post(requestUrl, { account_id: phonecom.voip_id, invoice_id })
    return response.data
}

/**
 *
 */
export {
    getInvoiceDetails,
    getInvoices,
    getInvoicePdfLink
}
