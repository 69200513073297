<template>
  <div
    class="list-page phone-numbers"
    data-test-id="phone-numbers-list-page"
  >
    <DynamicFilters>
      <DynamicFilterInput
        v-model="phone.filters.name_or_number"
        :model="phone"
        :disabled="phone.loading"
        maxlength="30"
        :placeholder="l.t('phones.filter-phone-numbers-by-nickname-or-number', 'Filter phone numbers by nickname or number')"
        :validation="[$validation.maxlength(30)]"
      />
    </DynamicFilters>
    <IndexPage
      :resource="phone"
      :disable_filters="true"
      :disable_delete_all="true"
    >
      <template v-slot:list>
        <v-simple-table class="list-table" data-test-id="phone-numbers-list-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="nickname">{{ l.t('app.phone-number', 'Nickname') }}</th>
              <th data-test-id="number" class="pl-0">{{ l.t('app.phone-number', 'Phone number') }}</th>
              <th data-test-id="description">{{ l.t('app.description', 'Description') }}</th>
              <th data-test-id="more-options"></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <!-- 4065472 -->
            <tr
              v-for="(item, i) in phone.items"
              :key="item['id']"
              :class="{'selected-row': item.selected}"
              :data-test-id="item.id"
              >
                <template v-if="phone.deleting_item !== item.id">
                  <td class="select-table-item" data-test-id="checkbox">
                    <w-checkbox
                      v-model="item.selected"
                      @click="checked($event, item)"
                      class="align-center justify-center"
                      :key="`phone-numbers-item-${item.id}-${item.selected}`"
                      hide-details="auto"
                      :data-test-id="`phone-numbers-selected-checkbox-${i}`"
                    />
                  </td>
                  <td :data-test-id="`nickname-${i}`" class="nowrap">
                    {{ item.name }}
                  </td>
                  <td class="nowrap overflow-hidden pl-0" data-test-id="phone-link">
                    <IndexListItem
                      :route="{
                        name: 'phone-numbers.show',
                        params: {
                          id: item['id'],
                          _phone_number: item
                        },
                      }"
                      :item="item"
                      :title="$options.filters.formatPhoneNumber(item['phone_number'])"
                      :key="item['id']"
                      :data-test-id="`phone-numbers-item-title-${i}`"
                    />
                  </td>
                  <td class="w-100" :data-test-id="`phone-numbers-item-route-analysis-${i}`">
                    {{ item["name"] }}: <span v-html="item.route_analysis.description"></span>
                  </td>
                  <td data-test-id="more-options">
                    <MoreOptions :options="more_options(item)" />
                  </td>
                </template>
                <td v-else colspan="4">
                  <w-loader size="small"/>
                </td>
              </tr>
          </tbody>
        </v-simple-table>
      </template>
    </IndexPage>
    <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="phone.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent, consoleUserCanAccessPageMiddleware} from 'helpers';
  import l from '../../../libs/lang';
  import PhoneNumber from '../../../models/PhoneNumber';
  import Tel from '../../elements/Tel.vue';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';
  import DynamicFilters from '../../elements/DynamicFilters.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';
  import DynamicFilterInput from '../../elements/form/DynamicFilterInput.vue';

  export default {
    components: {
      Tel,
      FormInput,
      IndexPage,
      MoreOptions,
      IndexListItem,
      DynamicFilters,
      ConfirmDeletion,
      DynamicFilterInput,
    },
    data() {
      return {
        l,
        phone_filter: null,
        deleting_item_id: null,
        user_can_access_visualizer: false,
        phone: new PhoneNumber(this.$session, vueComponent(this)),
      };
    },
    async created() {
      const route = this.$router.options.routes.find((x) => x.name === 'phone-numbers.visualizer');
      this.$data.user_can_access_visualizer = await consoleUserCanAccessPageMiddleware(route, this.$branding, this.$session);
    },
    watch: {
      'phone.filters.exact': function (val) {
        if (!val) {
          if (this.phone_filter) {
            this.phone.filters.number = this.phone_filter;
          }
        }
     },
    },
    methods: {
      more_options(item) {
        const options = [
          {
            cb: () => this.$router.push({
              name: 'phone-numbers.show',
              params: {
                id: item['id'],
                _phone_number: item
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
        if (this.$data.user_can_access_visualizer) {
          options.push({
            cb: () => this.$router.push({
              name: 'phone-numbers.visualizer',
              params: {
                id: item['id'],
              },
            }),
            title: l.t('lr.delete-script', 'Visualizer'),
          });
        }
        return options;
      },
      IsNumberSelected() {
        const selected = window.getSelection().toString();

        if (!selected) return false;

        const cleanNumber = selected.replace(/-/g, '').trim();

        return this.$data.phone.items && !!this.$data.phone.items.find((x) => x.phone_number === cleanNumber);
      },
      checked(event, item) {
        this.$data.phone.checked(event, item);
        this.$emit('forceUpdate');
      },
    },
  };
</script>
