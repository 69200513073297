import { V4ErrorType } from 'interfaces'

/**
 * @param {object} fields - api error err['@error']['fields']
 */
const stringifyErrorFields = (fields: Record<string, unknown>) => {
    const message: string[] = []
    for (const field of Object.keys(fields)) {
        let key: string | string[] = field
            .replace(/:/g, '')
            .replace(/@(\w+)/g, '')
            .replace('_', ' ').split('.')
        key = key.map((x) => x.charAt(0).toUpperCase() + x.slice(1)).filter((x) => x)
        key = key.join(' / ')
        if (fields[field] && typeof fields[field] === 'object') {
            message.push(`${key}: ${stringifyErrorFields(fields[field])}`)
            continue
        }
        const keyMessage = Array.isArray(fields[field]) ? fields[field].join('. ') : fields[field]
        message.push(`${key}: ${keyMessage}`)
    }

    return message.join(' ')
}

/**
 * @param {object} err - error object
 */
const getValidationError = (err: V4ErrorType): string | null => {
    console.log(err)
    let message: string | null = null
    if (err?.['@error']?.fields && Object.keys(err['@error'].fields).length) {
        const { fields } = err['@error']
        message = stringifyErrorFields(fields)
    } else if (
        err &&
        err['@error'] &&
        err['@error']['@message']
    ) {
        message = err['@error']['@message']
    } else if (err.suppressed && err.message) {
        // eslint-disable-next-line prefer-destructuring
        message = err.message
    }
    if (message) message = message.split('.').join(' ')

    return message
}

/**
 *
 */
export {
    stringifyErrorFields
}

export default getValidationError
